<template>
  <div class="header">
    <div class="logoAll">
      <img :src="logo" @click="toHome" class="logo" alt="" />
    </div>
    
    <div class="menu" v-if="menuList.length">
      <p v-for="(item,index) in menuList" :key="item.id" @click="menuChange(item,index)" :class="{active:navIndex == index}">{{item.text}}</p>
    </div>
    <div class="tuoyuSearch" :style="{width:menuList.length ? '337px' : '457px'}" v-if="$route.params.plat_type !== 'guangxi'">
      <input v-model="keyWords"  @keyup.enter="goSearch()" placeholder="请输入您需要搜索的课程" />
      <div class="btn"  @click="goSearch">搜索</div>
    </div>
    <p style="flex:1"></p>
    <div class="login" v-if="!isLogin &&$route.params.plat_type !== 'guangxi'" @click="$store.commit('changeLoginShow', true)">
      <div>登录</div>
      <div class="login-btn-divider"></div>
      <div>注册</div>
    </div>
    
    <van-popover v-model="showPopover" trigger="click" overlay>
      <div class="popover">
        <ul>
          <li v-for="(item, index) in centerList" :key=" index" @click="jumpList(item.url)"><img :src="item.img"/><p>{{ item.text }}</p><i class="el-icon-arrow-right"></i></li>
        </ul>
        
        <p @click="logout" class="btn">退出登录</p>
      </div>
      <template #reference>
        <div class="user-info" v-if="isLogin && $route.params.plat_type !== 'guangxi'">
          <van-image round fit="cover" lazy-load @error="error"></van-image>
          <img
            style="
              width: 34px;
              height: 34px;
              border-radius: 50%;
              overflow: hidden;
              flex-shrink: 0;
              border: 1px solid #fff;"
            :src="
              (myUser && myUser.userface) ||
              'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userLogo.jpg'
            "
            alt=""
          />
          <span style="margin-left: 10px; color: #333">{{
            myUser ? myUser.nickname : "用户昵称"
          }}</span>
        </div>
      </template>
    </van-popover>

    <van-popup
      overlay-class="myOverlay"
      v-model="show"
      get-container="body"
      @click-overlay="$store.commit('changeLoginShow', false)"
    >
      <div class="login-main">
        <div class="login-title">验证码登录</div>
        <div class="phone-number">
          <van-field v-model="phone" center clearable placeholder="请输入手机号">
          </van-field>
        </div>
        <div class="auth-code">
          <van-field v-model="sms" center placeholder="请输入验证码">
            <template #button>
              <div
                class="code-btn"
                @click="sendSms"
                :class="smsNum == 60 ? '' : 'no_touch'"
              >
                {{ smsNum == 60 ? smsText : smsNum + "s后重试" }}
              </div>
            </template>
          </van-field>
        </div>
        <van-button color="#0080d6" class="login-btn" @click="login">登录</van-button>

        <div class="agreement">
          <p>
            <van-checkbox
              icon-size="14px"
              v-model="checked"
              checked-color="#0080d6"
              shape="square"
            >
              我同意并愿意接受讲坛</van-checkbox
            >
          </p>
          <p><span>《用户服务协议》</span>和<span>《隐私政策》</span></p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
module.exports = {
  data: function () {
    return {
      keyWords:'',
      navIndex: 0,
      menuList:[],
      centerList:[{
        text: '个人中心',
        img: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/center.png',
        url: 'myCenter'
        },{
        text: '课程兑换',
        img: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/exchange.png',
        url: 'exchange'
        }
      ],
      activeColor: "#a40001",
      activeBg: "#a40001",
      hidden: false,
      vanSet: false,
      keyword: "",
      phone: "",
      sms: "",
      user: "",
      checked: true,
      smsLoading: false,
      smsTimer: "",
      smsNum: 60,
      smsText: "获取验证码",
      showPopover: false,
      actions: [
        {
          text: "退出登录",
        },
      ],
      loading: null,
    };
  },
  mounted() {
  
    if (this.$route.name == "molecule") {
      this.$store.commit("SET_HEADER", "molecule");
    } else {
      this.$store.commit("SET_HEADER", "");
    }
    
    this.user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    this.activeColor = this.active == "molecule" ? "#a40001" : "";
    this.activeBg = this.active == "molecule" ? "#a40001" : "#fff";

    if(this.plat==107){
      this.menuList=[{
            text: '首页',
            url: 'index',
      },{
            text: '育儿专家课堂',
            url: 'lessonTuoyu',
            id: 230,
      },{
            text: '育儿百科系列',
            url: 'lessonTuoyu',
            id: 192,
      },{
            text: '育儿宝库',
            url: 'lessonTuoyu',
            id: 221,
      }
      // ,{
      //       text: '直播',
      //       url: 'liveTuoyu',
      // }
      ],
      this.centerList=[{
        text: '个人中心',
        img: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/center.png',
        url: 'myCenter'
        }]
    }
  },
  methods: {
    toHome() {
      let url = "./index";
      console.log(this.$until);
      this.$router.push(url)
      // this.$until.toPageOpen(url);
    },
    /**取cookie */
    getCookie(objName) {
      //获取指定名称的cookie的值
      var arrStr = document.cookie.split("; ");
      for (var i = 0; i < arrStr.length; i++) {
        var temp = arrStr[i].split("=");
        if (temp[0] == objName) return unescape(temp[1]); //解码
      }
      return "";
    },
    error() {
      this.user.userface =
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/pad/amfababy_assess/avatar.png";
    },
    logout() {
      this.showPopover = false;
      localStorage.removeItem("user");
      this.$store.commit("changeLoginShow", false);
      console.log('退出登录');
      this.$store.commit("SET_LOGINSTATUS", false);
      this.$store.commit("SET_USER", null);
    },
    login() {
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!this.sms) {
        this.$toast("请输入验证码");
        return;
      }
      console.log(!this.checked);
      if (!this.checked) {
        this.$toast("请勾选服务协议");
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$api({
        url: "/api/auth/login",
        method: "GET",
        data: {
          mobile: this.phone,
          code: this.sms,
          channel: 8,
        },
      })
        .then((res) => {
          console.log(res);
          this.loading.close();
          if (res.code == 0) {
            this.$toast(res.msg);
            this.clearTimer();
            let { id, mobile, nickname, userface, access_token } = res.data;
            this.user = {
              id,
              mobile,
              nickname,
              userface,
              access_token,
            };
            localStorage.setItem("user", JSON.stringify(this.user));
            this.$store.commit("SET_USER", this.user);
            // this.setCookie('user', JSON.stringify(this.user))
            this.$store.commit("changeLoginShow", false);
            this.$store.commit("SET_LOGINSTATUS", true);
            this.$store.dispatch("getStudy");
            this.$store.dispatch("getVIPInfo");
            location.reload()
          } else {
            this.$toast(res.msg);
          }
        })
        .catch((err) => {
          this.loading.close();
          console.log(err);
        });
    },
    /**存cookie */
    setCookie(objName, objValue, objHours) {
      var str = objName + "=" + escape(objValue); //编码
      if (objHours > 0) {
        //为0时不设定过期时间，浏览器关闭时cookie自动消失
        var date = new Date();
        var ms = objHours * 3600 * 1000;
        date.setTime(date.getTime() + ms);
        str += "; expires=" + date.toGMTString();
      }
      document.cookie = str;
    },
    sendSms() {
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!this.isPhoneNo(this.phone)) {
        this.$toast("手机号格式错误");
        return;
      }
      if (this.smsNum < 60) return;
      if (this.smsLoading) {
        return;
      }
      this.smsLoading = true;
      this.$api({
        url: "/api/auth/sms",
        method: "POST",
        data: {
          mobile: this.phone,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.$toast(res.msg);
            this.smsNum = this.smsNum - 1;
            this.smsTimer = setInterval(() => {
              this.smsNum = this.smsNum - 1;
              if (this.smsNum == 0) {
                this.smsNum = 60;
                this.clearTimer();
              }
            }, 1000);
          } else {
            this.$toast(res.msg);
          }
          this.smsLoading = false;
        })
        .catch((err) => {
          this.smsLoading = false;
          console.log(err);
        });
    },
    isPhoneNo(phone) {
      var pattern = /^1[3456789]\d{9}$/;
      return pattern.test(phone);
    },
    clearTimer() {
      if (this.smsTimer) {
        clearInterval(this.smsTimer);
        this.smsNum = 60;
      }
    },
    goSearch() {
     
      this.$until.toPageOpen('/search2?keywords='+this.keyWords)
    },
    menuChange(item,index) {
      let url = '/'+item.url
      if(item.id){
        url=url+'?id='+item.id
      }
      this.$until.toPageOpen(url);
    },
    jumpList(name) {
      // console.log(window.location);

      // if(this.user){
      //   this.setCookie('user', JSON.stringify(this.user))
      // }
      // return
      if (name == "jiazhang03") {
        window.open(
          window.location.origin + window.location.pathname + "#/jiazhang03/index"
        );
        return false;
      } else if (name == "jiazhang06") {
        window.open(
          window.location.origin + window.location.pathname + "#/jiazhang06/index"
        );
        return false;
      } else {
        // console.log(name);
        this.$router.push(`./${name}`);
      }
      return true;
    },
  },
  computed: {
    myUser(){
      return this.$store.state.user;
    },
    isLogin(){
      return this.$store.state.isLogin;
    },
    show() {
      return this.$store.state.isLoginShow;
    },
    studyInfo() {
      return this.$store.state.studyInfo;
    },
    active: {
      get() {
        return this.$store.state.headerActive;
      },
      set(value) {
        return this.$store.headerActive;
      },
    },
    plat() {
      return this.$store.state.plat_id;
    },
    name() {
      return this.$store.state.plat.name;
    },
    logo() {
      return this.$store.state.plat.logo;
      // return "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyulogo.png";
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  watch: {

    showPopover(e) {
      if (e) {
        this.$store.dispatch("getStudy");
      }
    },
    $route(newval, oldval) {
      
      if(this.menuList.length){
        this.navIndex = this.menuList.findIndex(item=>{
          return item.url == newval.name && (newval.query.id ? item.id==newval.query.id : true)
        })
      }
      
      this.showPopover = false;
      if (newval.name == "molecule") {
        console.log("purchased");
        this.$store.commit("SET_HEADER", "molecule");
      } else {
        this.$store.commit("SET_HEADER", "");
      }
    },
  },
};
</script>
<style lang="scss">
$themeColor: #2e66d0;
.van-overlay{
  background-color: transparent !important;
}
.myOverlay{
  background-color: rgba($color: #000000, $alpha: 0.7) !important;
}
.logoAll {
  width: 230px;
  word-break: keep-all;
  .logo {
    cursor: pointer;
    width: auto !important;
  }
}
.login-main {
  .code-btn {
    color: $themeColor !important;
  }
  .agreement {
    p > span {
      color: $themeColor !important;
    }
  }
}

.user-info {
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    // height: 24px;
    width: 80px;
  }
}
.tuoyuSearch {
  height: 40px;
  // overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
  input {
    height: 40px;
    line-height: 40px;
    flex: 1;
    color: #999999;
    font-size: 14px;
    padding-left: 10px;
    border-radius: 8px 0 0 8px;
    box-sizing: border-box;
    background: #f9f9f9;
    border: 1px solid #f0f0f0;
  }
  .btn {
    border: 1px solid $themeColor;
    width: 80px;
    height: 40px;
    background: $themeColor;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 8px 8px 0;
    color: #fff;
  }
}
</style>
<style scoped lang="scss">
$themeColor: #2e66d0;
.login {
  
  width: 96px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dddddd;
  color: $themeColor;
  font-size: 14px;
  border-radius: 8px;
  margin-left: auto;
  cursor: pointer;
  .login-btn-divider {
    background: $themeColor;
    width: 1px;
    height: 13px;
    margin: 0 5px;
  }
}
.user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 96px;
  height: 36px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  color: #a40001;
  margin-left: 30px;
}
.header .menu {
  margin-right: 40px;
  width: 365px;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  padding-left: 10px;
  p{
    cursor: pointer;
    display: flex;
    align-items: center;
    img{
      margin-right: 7px;
    }
  }
  p:hover,.active{
    color: $themeColor;
    font-weight: bold;
    background: none;
  }
}
.van-tab {
  font-size: 14px !important;
}
.popover {
  padding: 24px 0;
  font-size: 14px;
  width: 220px;
  ul {
    li {
      display: flex;
      padding: 5px 24px;
      height: 40px;
      line-height: 40px;
      align-items: center;
      cursor: pointer;
      &:hover{
        background: #e4e4e4;
      }
      img{
        margin-right: 12px;
      }
      p {
        flex: 1;
        font-size: 16px;
      }
    }
  }
  .btn {
    border: 1px solid #e4e4e4;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
    width: 90%;
    margin: 20px auto 0;
    text-align: center;
    color: $themeColor;
    cursor: pointer;
    border-radius: 20px;
    &:hover{
      background: $themeColor;
      color: #fff
    }
  }
}
</style>
